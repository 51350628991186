import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle``

export const hspw = {
  primary_color: '#BE08FB',
  secondary_color: '#01E1D6',
  gradient_color: '#e678ef',
  text_primary_color: '#009BC1'
}

export const sada = {
  primary_color: '#FF6666',
  secondary_color: '#6633CC',
  gradient_color: '#6633CC',
  text_primary_color: '#00C9BD'
}

export const luft = {
  primary_color: '#8ccc82',
  secondary_color: '#122a75',
  gradient_color: '#122a75',
  text_primary_color: '#00C9BD'
}

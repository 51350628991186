import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import PrivateRoute from './PrivateRoute'
import loadable from '@loadable/component'
import LoadingLogo from '../../components/LoadingLogo'
import Helmet from 'react-helmet'

const Login = loadable(() => import('../login'), {
  fallback: LoadingLogo
})
//const Home = loadable(()=> import('../home/Home'), { fallback: LoadingLogo })
//const Home_en = loadable(() => import('../home/Home_en'), { fallback: LoadingLogo })
//const Home_ptBR = loadable(() => import('../home/Home_ptBR'), { fallback: LoadingLogo })
// const Admin = loadable(() => import('../admin/Admin'), {
//   fallback: LoadingLogo
// })
// const Forms = loadable(() => import('../admin/forms/Forms'), {
//   fallback: LoadingLogo
// })
const NotFound = loadable(() => import('../not-found'), {
  fallback: LoadingLogo
})
const InvitationNotFound = loadable(() => import('../invitation-not-found'), {
  fallback: LoadingLogo
})
const UserVoucher = loadable(() => import('../user-voucher/UserVoucher'), {
  fallback: LoadingLogo
})
const UserFirstAccess = loadable(() => import('../user-first-access'), {
  fallback: LoadingLogo
})
const UserRecoveryPassword = loadable(
  () => import('../user-data/UserRecoveryPassword'),
  { fallback: LoadingLogo }
)
const AppDownload = loadable(() => import('../user-data/app-download'), {
  fallback: LoadingLogo
})
const UserDashboard = loadable(
  () => import('../user-dashboard/UserDashboard'),
  { fallback: LoadingLogo }
)
const UserData = loadable(() => import('../user-data/UserData'), {
  fallback: LoadingLogo
})
// const AdminCompany = loadable(() => import('../admin/AdminCompany'), {
//   fallback: LoadingLogo
// })
const UserReport = loadable(() => import('../user-report/UserReport'), {
  fallback: LoadingLogo
})
const EcoSystem = loadable(() => import('../eco-system'), {
  fallback: LoadingLogo
})
const DependentsRegister = loadable(
  () => import('../dependents/dependents-register'),
  {
    fallback: LoadingLogo
  }
)
// const DependentsEdit = loadable(
//   () => import('../admin/users/dependents-form'),
//   {
//     fallback: LoadingLogo
//   }
// )
const DayTip = loadable(() => import('../day-tip/DayTip'), {
  fallback: LoadingLogo
})
const UserReportPdf = loadable(() => import('../user-report/UserReportPdf'), {
  fallback: LoadingLogo
})
const UserRequestRecovery = loadable(
  () => import('../user-data/UserRequestRecovery'),
  { fallback: LoadingLogo }
)
const MudancaHabitos = loadable(() => import('../changing-habits'), {
  fallback: LoadingLogo
})
const MudancaHabitosMinhaLista = loadable(
  () => import('../changing-habits/my-list'),
  {
    fallback: LoadingLogo
  }
)
const MudancaHabitosLista = loadable(() => import('../changing-habits/list'), {
  fallback: LoadingLogo
})
const MudancaHabitosTrilha = loadable(
  () => import('../changing-habits/trail'),
  {
    fallback: LoadingLogo
  }
)
const MudancaHabitosPlay = loadable(() => import('../changing-habits/play'), {
  fallback: LoadingLogo
})
const MudancaHabitosExpert = loadable(
  () => import('../changing-habits/expert'),
  {
    fallback: LoadingLogo
  }
)
const MudancaHabitosArticleExpert = loadable(
  () => import('../changing-habits/article-expert'),
  {
    fallback: LoadingLogo
  }
)
const UserPreRegistration = loadable(
  () => import('../user-pre-registration/index'),
  {
    fallback: LoadingLogo
  }
)
const NotificationList = loadable(
  () => import('../notification/notification-list'),
  {
    fallback: LoadingLogo
  }
)

const Schedule = loadable(() => import('../schedule'), {
  fallback: LoadingLogo
})

const Delivery = loadable(() => import('../delivery'), {
  fallback: LoadingLogo
})

const RouterComponent = () => {
  return (
    <Router>
      <div style={{ backgroundColor: '#FFF' }}>
        <Helmet>
          <link
            rel="icon"
            type="image/png"
            href={process.env.REACT_APP_FAVICON}
            sizes="16x16"
          />
          <title>{process.env.REACT_APP_WEBSITE_NAME}</title>
        </Helmet>
        <Switch>
          <Route path="/" exact component={Login} />
          {/* <PrivateRoute path="/admin" component={Admin} /> */}
          {/* <PrivateRoute path="/admin-company" component={AdminCompany} /> */}
          {/* <PrivateRoute path="/forms" component={Forms} /> */}
          <Route path="/login" component={Login} />
          {/*Usuário 1* acesso por voucher - curto para SMS */}
          <Route path="/v" component={UserVoucher} />
          {/* Recuperar senha por voucher - curto para SMS */}
          <Route path="/pr" component={UserRecoveryPassword} />
          <Route path="/app-download" component={AppDownload} />
          <Route path="/user-first-access" component={UserFirstAccess} />
          <Route path="/recovery" component={UserRequestRecovery} />
          <Route path="/schedule" component={Schedule} />

          <PrivateRoute path="/user-dashboard" component={UserDashboard} />
          <PrivateRoute path="/user-data" component={UserData} />
          <PrivateRoute path="/user-report" component={UserReport} />
          <PrivateRoute path="/eco-system" component={EcoSystem} />
          <PrivateRoute path="/dependents" component={InvitationNotFound} />
          <PrivateRoute
            path="/dependents-register"
            component={DependentsRegister}
          />
          <Route path="/c" component={UserPreRegistration} />
          {/* <PrivateRoute path="/dependents-form" component={DependentsEdit} /> */}
          <PrivateRoute path="/changing-habits" component={MudancaHabitos} />
          <PrivateRoute path="/my-list" component={MudancaHabitosMinhaLista} />
          <PrivateRoute path="/list" component={MudancaHabitosLista} />
          <PrivateRoute path="/trail" component={MudancaHabitosTrilha} />
          <PrivateRoute path="/play" component={MudancaHabitosPlay} />
          <PrivateRoute path="/expert" component={MudancaHabitosExpert} />
          <PrivateRoute
            path="/notification-list"
            component={NotificationList}
          />
          <PrivateRoute
            path="/article-expert"
            component={MudancaHabitosArticleExpert}
          />
          <PrivateRoute path="/day-tip" component={DayTip} />
          <Route path="/baixar-pdf" component={UserReportPdf} />
          <Route path="/delivery" component={Delivery} />
          <Route path="*" exact component={NotFound} />
        </Switch>
      </div>
    </Router>
  )
}

export default RouterComponent

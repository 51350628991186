export const addUser = item => dispatch => {
    dispatch({
        type: 'ADD_USER',
        payload: item,
    })
}

export const emptyUser = () => dispatch => {
    dispatch({
        type: 'EMPTY_USER',
    })
}
import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'
import pt_br from './translations/pt_BR.json'
import en from './translations/en.json'
import es from './translations/es.json'

const resources = {
  'pt-BR': pt_br,
  en: en,
  es: es
}

i18n
  .use(Backend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'pt-BR',
    debug: process.env.REACT_APP_AMBIENTE === 'local',
    fallbackLng: 'pt-BR',
    interpolation: {
      escapeValue: false
    },
    react: { useSuspense: false }
  })

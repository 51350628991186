import styled from 'styled-components'

export const ButtonAccept = styled.button`
  background: ${props => props.theme.primary_color};
  font-weight: bold;
  opacity: 0.9;
  :hover{
    opacity: 1;
  }
`
export const Span = styled.span`
  color: ${props => props.theme.primary_color};
`
export const CookiesBar = styled.span`
  border-top: 3px solid ${props => props.theme.primary_color};
  background-color: #000;
  opacity: 0.9;
  max-height: 360px;
`
const initialState = {
  title: 'Pergunte ao especialista',
  script: 'chatbot',
  mainColor: process.env.REACT_APP_PRIMARY_COLOR,
  local: undefined,
  opened: false,
  diagnostico_resultado: undefined
}

const ChatbotReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'ADD_CHATBOT':
      return {
        ...state,
        ...action.payload
      }
    case 'RESET_CHATBOT':
      return {
        ...state,
        ...initialState
      }
    default:
      return state
  }
}

export default ChatbotReducer

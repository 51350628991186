export const USER_STORAGE = '@hspw-user-storage'

class UserStorage {
  getItem() {
    localStorage.getItem(USER_STORAGE)
  }

  getItemJson() {
    const userStorage = localStorage.getItem(USER_STORAGE)
    if (userStorage && userStorage !== '') return JSON.parse(userStorage)
    return null
  }

  setSorteio(username, valor) {
    localStorage.setItem(USER_STORAGE + '-' + username + '-sorteio', valor)
  }

  getSorteio(username) {
    return localStorage.getItem(USER_STORAGE + '-' + username + '-sorteio')
  }

  setAcceptedCookies(cookiesState) {
    localStorage.setItem(USER_STORAGE + '-acceptedCookies', cookiesState)
  }

  getAcceptedCookies() {
    const cookiesState = localStorage.getItem(USER_STORAGE + '-acceptedCookies')
    return cookiesState ? JSON.parse(cookiesState) : {}
  }

  setNickname(username, nickname) {
    localStorage.setItem(
      USER_STORAGE + '-' + username + '-nickname',
      nickname ?? ''
    )
  }

  setDependentes(username, status) {
    localStorage.setItem(
      USER_STORAGE + '-' + username + '-dependentes',
      status ?? ''
    )
  }

  getNickname(username) {
    return localStorage.getItem(USER_STORAGE + '-' + username + '-nickname')
  }

  removeFiltroUsuarios(username) {
    localStorage.removeItem(USER_STORAGE + '-' + username + '-filtroUsuarios')
  }

  setFiltroUsuarios(username, filtro) {
    localStorage.setItem(
      USER_STORAGE + '-' + username + '-filtroUsuarios',
      filtro
    )
  }

  getFiltroUsuarios(username) {
    const filtro = localStorage.getItem(
      USER_STORAGE + '-' + username + '-filtroUsuarios'
    )
    return filtro ? JSON.parse(filtro) : {}
  }

  removeFiltroPedidosAjuda(username) {
    localStorage.removeItem(
      USER_STORAGE + '-' + username + '-filtroPedidosAjuda'
    )
  }

  setFiltroPedidosAjuda(username, filtro) {
    localStorage.setItem(
      USER_STORAGE + '-' + username + '-filtroPedidosAjuda',
      filtro
    )
  }

  getFiltroPedidosAjuda(username) {
    const filtro = localStorage.getItem(
      USER_STORAGE + '-' + username + '-filtroPedidosAjuda'
    )
    return filtro ? JSON.parse(filtro) : {}
  }

  setItem(store) {
    if (!store || typeof store !== 'string')
      throw new Error('Store deve ser do tipo string!')

    localStorage.setItem(USER_STORAGE, store)
  }

  setToken(token) {
    const user = this.getItemJson()

    if (user) {
      localStorage.setItem(USER_STORAGE, JSON.stringify({
        ...user,
        access_token: token
      }))
    }
  }

  removeItem() {
    localStorage.removeItem(USER_STORAGE)
  }
}

export default new UserStorage()

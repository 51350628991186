import React from 'react'
import './App.css'
import RouterComponent from './views/router/Router'
import { Provider as ProviderRedux } from 'react-redux'
import Store from './redux/Store'
import CookiesBar from './components/CookiesBar'
import './locales'

function App() {
  return (
    <ProviderRedux store={Store}>
      <RouterComponent />
      <CookiesBar />
    </ProviderRedux>
  )
}

export default App

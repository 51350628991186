import thunk from 'redux-thunk'
import UserReducer from './reducers/UserReducer'
import { applyMiddleware, combineReducers, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import ChatbotReducer from './reducers/ChatbotReducer'

const rootReducers = combineReducers({
  userStore: UserReducer,
  chatbotStore: ChatbotReducer
})

export default createStore(
  rootReducers,
  composeWithDevTools(applyMiddleware(thunk))
)

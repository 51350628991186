const initialState = {
	user: {},
}

const UserReducer = (state = initialState, action) => {
	switch (action.type) {
	case 'ADD_USER':
		return {
			...state,
			user: action.payload,
		}
	case 'EMPTY_USER':
		return {
			...state,
			user: {},
		}
	default:
		return state
	}
}

export default UserReducer